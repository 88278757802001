.Member {
    background-color: #ffffff;
}

.Member-header{
    width: 100vW;
    background-color: #d9d9d9;
}

.Member-header-contents{
    width: 100vW;
    position: relative;
}

.Member-header-back{
    position: absolute;
    line-height: 50px;
    font-weight: bold;
    padding-left: 24px;
    cursor: pointer;
}

.Member-header-title{
    line-height: 50px;
    font-weight: bold;
    text-align: center;
}

.Member-body{
    margin: 0 auto;
    width: 100vW;
}

.Member-title{
    color: #7e7e7e;
    font-weight: bold;
}

.Member-item{
    display: flex;
    height: 76px;
    color: #000000;
    font-weight: bold;
    /* text-align: left; */
    /* line-height: 76px; */
    border-bottom: 2px solid #d8d8d8;
}

.Member-icon{
    width: 56px;
    height: 56px;
    padding: 10px 0 10px 20px;
}
.Member-icon img{
    width: 56px;
    height: 56px;
}

.Member-name{
    display: flex;
    color: #000000;
    font-weight: bold;
    padding-left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.Member-button{
    width: 140px;
    line-height: 30px;
    color: #ffffff;
    font-weight: bold;
    margin:14px auto;
    background-color: #ffa500;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 0 2px gray;
    cursor: pointer;
}

.Member-dialog{
    width: 100%;
    border:  2px solid #7e7e7e;
    border-radius: 10px;
    max-width: 330px;
}

.Member-dialog-title {
    color: #000000;
    font-weight: bold;
    text-align: center;
}

.Member-dialog-contents {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
}

.Member-cover {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    z-index: 999;
}
.Member-cover-oval {
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.dli-chevron-round-left {
    display: inline-block;
    vertical-align: middle;
    color: #7e7e7e;
    line-height: 17px;
    position: relative;
    width: 17px;
    height: 17px;
    transform: translateX(25%) rotate(-135deg);
}

.dli-chevron-round-left::before, .dli-chevron-round-left::after {
    content: '';
    position: absolute;
    background: currentColor;
    border-radius: 0.1em;
}

.dli-chevron-round-left::before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.1em;
}

.dli-chevron-round-left::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.1em;
}

.delete-btn{
    margin: auto;
    margin-right: 40px;
}

.round_btn {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    border: 2px solid #333;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

.round_btn::before, .round_btn::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 22px;
    background: #000;
}

.round_btn::before {
    transform: translate(-50%,-50%) rotate(45deg);
}

.round_btn::after {
    transform: translate(-50%,-50%) rotate(-45deg);
}
