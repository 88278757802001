.Register {
    background-color: #ffffff;
}

.Register-header{
    width: 100vW;
    background-color: #d9d9d9;
}

.Register-header-contents{
    width: 100vW;
    position: relative;
}

.Register-header-back{
    position: absolute;
    line-height: 50px;
    font-weight: bold;
    padding-left: 24px;
    cursor: pointer;
}

.Register-header-title{
    line-height: 50px;
    font-weight: bold;
    text-align: center;
}

.Register-body{
    margin: 0 auto;
    padding: 20px 10px;
    width: 100vW;
    max-width: 330px;
}

.Register-title{
    width: 294px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
    /* margin-bottom: 10px; */
    background-color: #ababab;
}

.Register-qrcode{
    width: 280px;
    height: 280px;
    margin: 0 auto;
    border: 7px solid #ababab;
}

.Register-explanation{
    border: 2px dashed #d8d8d8;
    border-radius: 15px;
    padding: 10px 10px 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Register-explanation-title{
    font-weight: bold;
}

.Register-explanation-text{
    margin: 10px 10px 10px 10px;
}

.Register-explanation-body-title{
    margin: 10px 10px 0 10px;
}
.Register-explanation-body-text{
    margin: 5px 20px 20px 20px;
}

.Register-explanation-button{
    width: 220px;
    line-height: 30px;
    color: #ffffff;
    font-weight: bold;
    background-color: #ffa500;
    text-align: center;
    border-radius: 20px;
}

.Register-job-title {
    width: 100px;
    text-align: center;
    font-weight: bold;
    line-height: 40px;
    color: #ffffff;
    background-color: gray;
    margin: 0 auto;
}

.Register-button{
    width: 240px;
    line-height: 40px;
    color: #ffffff;
    font-weight: bold;
    margin: 20px auto;
    background-color: #ffa500;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 2px gray;
    cursor: pointer;
}

.dli-chevron-round-left {
    display: inline-block;
    vertical-align: middle;
    color: #7e7e7e;
    line-height: 17px;
    position: relative;
    width: 17px;
    height: 17px;
    transform: translateX(25%) rotate(-135deg);
}

.dli-chevron-round-left::before, .dli-chevron-round-left::after {
    content: '';
    position: absolute;
    background: currentColor;
    border-radius: 0.1em;
}

.dli-chevron-round-left::before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.1em;
}

.dli-chevron-round-left::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.1em;
}
