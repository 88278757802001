.History {
    background-color: #ffffff;
}

.History-header{
    width: 100vW;
    background-color: #d9d9d9;
}

.History-header-contents{
    width: 100vW;
    position: relative;
}

.History-header-back{
    position: absolute;
    line-height: 50px;
    font-weight: bold;
    padding-left: 24px;
    cursor: pointer;
}

.History-header-title{
    line-height: 50px;
    font-weight: bold;
    text-align: center;
}

.History-body{
    margin: 0 auto;
    width: 100vW;
}

.History-tub{
    display: flex;
    height: 40px;
    border-bottom: 1px solid #E7E0EC;
}

.History-tub-item{
    width: 50%;
    line-height: 40px;
    text-align: center;
    color: #6B6670;
    cursor: pointer;
}

.History-tub-item-active{
    width: 50px;
    line-height: 40px;
    color: #6750A5;
    margin: 0 auto;
}
.History-tub-item-active:after {
    background-color: #6750A5; /* 線色 */
    border-radius: 5px 5px 0 0; /* 線幅の半分 */
    content: "";
    display: block;
    height: 3px; /* 線幅 */
    margin-top: -2px;
}

.History-cover {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
}
.History-cover-oval {
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.History-contents{
    width: 100%;
}

.History-item{
    color: #000000;
    text-align: left;
    margin: 10px;
    cursor: pointer;
}

.History-item-date{
}


.History-item-message{
    font-weight: bold;
}

.History-item-top{
    border-top: 1px solid #d8d8d8;
}

.History-delete-button{
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 3%;
    right: 3%;
    border-radius: 30%;
    text-align: center;
    background-color: #fff;
    box-shadow: 1px 2px 6px gray;
    cursor: pointer;
}
.History-delete-button img{
    padding: 12px;
    width: 16px;
    height: 16px;
}


.dli-chevron-round-left {
    display: inline-block;
    vertical-align: middle;
    color: #7e7e7e;
    line-height: 17px;
    position: relative;
    width: 17px;
    height: 17px;
    transform: translateX(25%) rotate(-135deg);
}

.dli-chevron-round-left::before, .dli-chevron-round-left::after {
    content: '';
    position: absolute;
    background: currentColor;
    border-radius: 0.1em;
}

.dli-chevron-round-left::before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.1em;
}

.dli-chevron-round-left::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.1em;
}
