.Qrcode {
    background-color: #ffffff;
}

.Qrcode-header{
    width: 100vW;
    background-color: #d9d9d9;
}

.Qrcode-header-contents{
    width: 100vW;
    position: relative;
}

.Qrcode-header-back{
    position: absolute;
    line-height: 50px;
    font-weight: bold;
    padding-left: 24px;
    cursor: pointer;
}

.Qrcode-header-title{
    line-height: 50px;
    font-weight: bold;
    text-align: center;
}

.Qrcode-body{
    margin: 0 auto;
    padding: 20px 10px;
    width: 100vW;
    max-width: 330px;
}

.Qrcode-title{
    color: #000;
    font-weight: bold;
    text-align: center;
    margin-bottom: 14px;
}

.Qrcode-contents{
    width: 300px;
    margin: 0 auto;
}

.Qrcode-scan{
    position: relative;
    z-index: 1;
}

.Qrcode-frame{
    position: absolute;
    width: 192px;
    height: 192px;
    border:  4px dashed #fff;
    margin: 50px;
    z-index: 3;
}
.Qrcode-frame-back{
    position: absolute;
    width: 200px;
    height: 200px;
    border:  50px solid #000;
    border-radius: 5px;
    opacity: 0.2;
    z-index: 2;
}
.Qrcode-video{
    position: absolute;
    width: 300px;
    height: 300px;
    background-color: #000;
    object-fit: cover;
    border-radius: 5px;
}
.Qrcode-canvas{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.Qrcode-button{
    width: 240px;
    line-height: 40px;
    color: #ffffff;
    font-weight: bold;
    margin:14px auto;
    background-color: #ffa500;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 2px gray;
    cursor: pointer;
    margin-top: 20px;
}

.Qrcode-dialog{
    width: 100%;
    border:  2px solid #7e7e7e;
    border-radius: 10px;
    max-width: 330px;
}

.Qrcode-dialog-title {
    color: #000000;
    font-weight: bold;
    text-align: center;
}

.Qrcode-dialog-contents {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
}

.Qrcode-cover {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #595959;
    opacity: 0.5;
    z-index: 9999;
}
.Qrcode-cover-oval {
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.dli-chevron-round-left {
    display: inline-block;
    vertical-align: middle;
    color: #7e7e7e;
    line-height: 17px;
    position: relative;
    width: 17px;
    height: 17px;
    transform: translateX(25%) rotate(-135deg);
}
.dli-chevron-round-left::before, .dli-chevron-round-left::after {
    content: '';
    position: absolute;
    background: currentColor;
    border-radius: 0.1em;
}
.dli-chevron-round-left::before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.1em;
}
.dli-chevron-round-left::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.1em;
}
