.font-navi-title {
    font-size: 20px;
}

.font-contents-title {
    font-size: 20px;
}

.font-contents-body {
    font-size: 18px;
}

.font-contents-sub-title {
    font-size: 18px;
}

.font-contents-sub-body {
    font-size: 16px;
}

.font-contents-history-time {
    font-size: 14px;
}
.font-contents-history-title {
    font-size: 16px;
}

.font-button {
    font-size: 18px;
}

.font-explanation-button {
    font-size: 16px;
}