.User {
    background-color: #ffffff;
}

.User-header{
    width: 100vW;
    background-color: #d9d9d9;
}

.User-header-contents{
    width: 100vW;
    position: relative;
}

.User-header-back{
    position: absolute;
    line-height: 50px;
    font-weight: bold;
    padding-left: 24px;
    cursor: pointer;
}

.User-header-title{
    line-height: 50px;
    font-weight: bold;
    text-align: center;
}

.User-body{
    margin: 0 auto;
    padding: 20px 10px;
    width: 100vW;
    max-width: 330px;
}

.User-logo{
    width: 70px;
    height: 70px;
    margin:0 auto;
    cursor: pointer;
}
.User-logo img{
    width: 70px;
    height: 70px;
}

.User-title{
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
}

.User-from-text{
    caret-color:lightcoral;
    width: 100%;
    color: #000000;
    margin-top: 6px;
    margin-bottom: 12px;
    padding: 4px 2px;
    border: none;
    border-bottom: 1px solid #d8d8d8;
}
input[type="text"]:focus,
textarea:focus{
  border:2px solid lightcoral;
  outline: none;
}

.User-from-send{
    width: 140px;
    line-height: 40px;
    color: #ffffff;
    font-weight: bold;
    margin:0 auto;
    background-color: #ffa500;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 2px gray;
    cursor: pointer;
    margin-top: 10px;
}


.User-contents{
    width: 100%;
    border: 2px solid #d8d8d8;
    border-radius: 15px;
}

.User-item{
    height: 47px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    padding-left: 16px;
    line-height: 47px;
}

.User-item-under{
    border-bottom: 2px solid #d8d8d8;
}

.User-dialog{
    width: 100%;
    border:  2px solid #7e7e7e;
    border-radius: 10px;
    max-width: 330px;
}

.User-dialog-title {
    color: #000000;
    font-weight: bold;
    text-align: center;
}

.User-dialog-contents {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
}

.User-dialog-icon {
    width: 56px;
    height: 56px;
    padding: 10px;
    cursor: pointer;
}
.User-button{
    width: 140px;
    line-height: 30px;
    color: #ffffff;
    font-weight: bold;
    margin:14px auto;
    background-color: #ffa500;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 0 2px gray;
    cursor: pointer;
}

.User-cover {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #595959;
    opacity: 0.5;
}
.User-cover-oval {
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.dli-chevron-round-left {
    display: inline-block;
    vertical-align: middle;
    color: #7e7e7e;
    line-height: 17px;
    position: relative;
    width: 17px;
    height: 17px;
    transform: translateX(25%) rotate(-135deg);
}

.dli-chevron-round-left::before, .dli-chevron-round-left::after {
    content: '';
    position: absolute;
    background: currentColor;
    border-radius: 0.1em;
}

.dli-chevron-round-left::before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.1em;
}

.dli-chevron-round-left::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.1em;
}
