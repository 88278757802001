.Menu {
    background-color: #ffffff;
}

.Menu-header{
    width: 100vW;
    background-color: #d9d9d9;
}

.Menu-header-title{
    line-height: 50px;
    font-weight: bold;
    padding-left: 24px;
}

.Menu-body{
    margin: 0 auto;
    padding: 20px 10px;
    width: 100vW;
    max-width: 330px;
}

.Menu-title{
    color: #7e7e7e;
    font-weight: bold;
}

.Menu-sub{
    color: #000000;
    font-weight: bold;
    margin: 10px;
}

.Menu-contents{
    width: 100%;
    border: 2px solid #d8d8d8;
    border-radius: 15px;
    margin-top: 4px;
}

.Menu-item{
    height: 47px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    padding-left: 16px;
    line-height: 47px;
    cursor: pointer;
}

.Menu-item-under{
    border-bottom: 2px solid #d8d8d8;
}
