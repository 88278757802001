.Detail {
    background-color: #ffffff;
}

.Detail-header{
    width: 100vW;
    background-color: #d9d9d9;
}

.Detail-header-contents{
    width: 100vW;
    position: relative;
}

.Detail-header-back{
    position: absolute;
    line-height: 50px;
    font-weight: bold;
    padding-left: 24px;
    cursor: pointer;
}

.Detail-header-title{
    line-height: 50px;
    font-weight: bold;
    text-align: center;
}

.Detail-body{
    margin: 0 auto;
    width: 100vW;
}

.Detail-cover {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    z-index: 999;
}
.Detail-cover-oval {
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.Detail-map{
    width: 100%;
    height: 340px;
}
.Detail-status{
    width: 96%;
    padding: 2%;
}
.Detail-user{
    display: flex;
}
.Detail-user img{
    height: 50px;
    width: 50px;
}
.Detail-user-detail{
    display: block;
    margin-left: 10px;
}
.Detail-type{
    color: #2C9BF3;
    font-weight: bold;
}
.Detail-name{
    font-weight: bold;
}
.Detail-date{
    margin-top: 14px;
}
.Detail-message{
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 60px;
}

.Detail-delete-button{
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    bottom: 3%;
    right: 3%;
    border-radius: 30%;
    text-align: center;
    background-color: #fff;
    box-shadow: 1px 2px 6px gray;
    cursor: pointer;
}
.Detail-delete-button img{
    padding: 12px;
    width: 25px;
    height: 25px;
}

.Detail-dialog{
    width: 100%;
    border:  2px solid #7e7e7e;
    border-radius: 10px;
    max-width: 330px;
    z-index: 9999;
}
.Detail-dialog-title {
    color: #000000;
    font-weight: bold;
    text-align: center;
}
.Detail-button{
    width: 140px;
    line-height: 30px;
    color: #ffffff;
    font-weight: bold;
    margin:14px auto;
    background-color: #ffa500;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 0 2px gray;
    cursor: pointer;
}

.dli-chevron-round-left {
    display: inline-block;
    vertical-align: middle;
    color: #7e7e7e;
    line-height: 17px;
    position: relative;
    width: 17px;
    height: 17px;
    transform: translateX(25%) rotate(-135deg);
}

.dli-chevron-round-left::before, .dli-chevron-round-left::after {
    content: '';
    position: absolute;
    background: currentColor;
    border-radius: 0.1em;
}

.dli-chevron-round-left::before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.1em;
}

.dli-chevron-round-left::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.1em;
}
